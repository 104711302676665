type GetWhatsAppTextMessageContentParams = {
    subject: string
    message: string
    fileUrls?: string[]
}

/*
    Function that formats a message for WhatsApp
    Handles HTML content with links, and file attachments
*/
export const getWhatsAppTextMessageContent = ({
    subject,
    message,
    fileUrls,
}: GetWhatsAppTextMessageContentParams): string => {
    // Extract and format links
    const links = extractLinks(message)

    // Clean HTML and format message
    // Separates message into 4 parts: subject, message, links, files
    const cleanedMessage = cleanHtmlAndFormatMessage({
        subject,
        message,
        links,
        fileUrls,
    })

    // Return WhatsApp ready message
    return encodeMessageForWhatsApp(cleanedMessage)
}

type ParsedLink = {
    text: string
    url: string
    formattedText: string
}

const extractLinks = (message: string): ParsedLink[] => {
    const linkRegex = /<a\s+(?:[^>]*?\s+)?href="([^"]*)"[^>]*>(.*?)<\/a>/g
    const links: ParsedLink[] = []

    let match
    while ((match = linkRegex.exec(message)) !== null) {
        // url comes from the href attribute
        // text comes from the content between the opening and closing tags
        const [_, url, text] = match
        // Skip payment links and portal link because they come in message
        if (url.includes('/pay/') || url.includes('/portal/')) continue
        const formattedText = text.trim()
        links.push({
            url,
            text,
            formattedText: `➜ *${formattedText}*`,
        })
    }

    return links
}

type CleanHtmlAndFormatMessageParams = {
    subject: string
    message: string
    links: ParsedLink[]
    fileUrls?: string[]
}

const cleanHtmlAndFormatMessage = ({
    subject,
    message,
    links,
    fileUrls,
}: CleanHtmlAndFormatMessageParams) => {
    // Clean HTML tags and normalize whitespace
    const cleanedMessage = message
        .replace(/<br\s*\/?>/gi, '\n')
        .replace(/<p>/gi, '')
        .replace(/<\/p>/gi, '\n\n') // Two for propper spacing between p tags
        .replace(/(<([^>]+)>)/gi, '')
        .replace(/\n\n\n+/g, '\n\n') // Prevents excessive spacing when multiple tags created newlines
        .trim()

    const messageParts = [`*${subject}*`, '', cleanedMessage]

    // Add formatted links if any exist
    if (links.length > 0) {
        messageParts.push(
            '\nEnlaces relevantes:',
            ...links.map((link) => `${link.formattedText}: ${link.url}`)
        )
    }

    // Add file URLs if any exist
    if (fileUrls && fileUrls?.length > 0) {
        // Ensures that spaces in URL have been encoded with %20
        const formattedFileUrls = fileUrls.map((fileUrl) => encodeURI(fileUrl.trim()))
        messageParts.push('\nArchivos adjuntos:', ...formattedFileUrls)
    }

    // Separate message parts by line
    return messageParts.join('\n')
}

// Allows URL to include newlines and special characters
// Better to use built'in browser function to not miss edge cases
const encodeMessageForWhatsApp = (message: string): string => {
    return encodeURIComponent(message.trim())
}
