import { FC, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'

import { ErrorMessage, Modal, Spinner } from 'ds/components'
import { SendNotificationForm } from './SendNotificationForm'

import { notificationService } from 'lib/services/notificationService'
import { getErrorFromQuery } from 'lib/utils/getErrorFromQuery'

type Props = {
    title: string
    paymentId?: number
    invoiceId?: number
    draftInvoiceId?: number
    customerId?: number
    paymentPlanId?: number
    template?: string
    attachments?: string[]
    attachInvoice?: boolean

    onClose: () => void
}

export const SendNotificationModal: FC<Props> = ({
    title,
    template,
    paymentId,
    customerId,
    paymentPlanId,
    invoiceId,
    draftInvoiceId,
    attachments,
    attachInvoice,
    onClose,
}) => {
    const queryParams = useMemo(
        () => ({
            template,
            invoice_id: invoiceId,
            draft_invoice_id: draftInvoiceId,
            payment_id: paymentId,
            customer_id: customerId,
            payment_plan_id: paymentPlanId,
        }),
        [invoiceId, draftInvoiceId, template, paymentId, customerId, paymentPlanId]
    )

    const { isLoading, error, data } = useQuery({
        queryKey: ['notificationContent', queryParams],
        queryFn: () => notificationService.getNotificationContent(queryParams),
        gcTime: 0,
    })

    const errorMessage = getErrorFromQuery(error, data)

    const renderBody = () => {
        if (isLoading) return <Spinner />
        if (errorMessage || !data) {
            return <ErrorMessage>Ha ocurrido un error: {errorMessage}</ErrorMessage>
        }

        const currAttachments = [...(attachments || []), ...(data.attachments || [])]

        return (
            <SendNotificationForm
                {...data}
                attachments={currAttachments}
                attachInvoice={attachInvoice}
                customerId={customerId}
                invoiceId={invoiceId}
                draftInvoiceId={draftInvoiceId}
                paymentPlanId={paymentPlanId}
                onSuccess={onClose}
                paymentId={paymentId}
            />
        )
    }

    return (
        <Modal title={title} onClose={onClose}>
            {renderBody()}
        </Modal>
    )
}
